/* eslint-disable */
<template>
	<v-container fluid>
		<v-toolbar elevation="0" class="transparent">
			<v-toolbar-title> Users </v-toolbar-title>
			<v-spacer />

			<v-btn icon @click="_fetchUsers"
				><v-icon>mdi-refresh</v-icon></v-btn
			>
			<v-btn icon @click="showAdd"
				><v-icon>mdi-plus-circle-outline</v-icon></v-btn
			>
			<v-spacer />
			<template #extension v-if="extended">
				<v-sheet width="100%" class="pa-4" outlined elevation="4">
					<v-row align="center" justify="center">
						<v-col>
							<v-text-field
								v-model="userStore.form.displayName"
								label="Name"
								hide-details
							/>
						</v-col>
						<v-col>
							<v-text-field
								v-model="userStore.form.email"
								label="Email"
								hide-details
							/>
						</v-col>
						<v-col cols="2">
							<v-btn class="primary" @click="_createUser"
								>add user</v-btn
							>
						</v-col>
						<v-col cols="1" align="right">
							<v-icon @click="extended = false">
								mdi-close</v-icon
							>
						</v-col>
					</v-row>
				</v-sheet>
			</template>
		</v-toolbar>
		<v-divider />
		<v-container>
			<v-data-table
				:items="userStore.userList"
				:headers="headers"
				:loading="loading"
			>
				<template v-slot:[`item.createdAt`]="{ item }">
					{{
						new Date(item.createdAt.seconds * 1000).toLocaleString()
					}}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon plain @click="openUserView(item.id)"
						><v-icon right>mdi-chevron-right</v-icon></v-btn
					>
				</template>
			</v-data-table>
		</v-container>
	</v-container>
</template>

<script>
	import { useUserStore } from "@/store/users";
	export default {
		name: "Users",
		setup() {
			const userStore = useUserStore();
			return { userStore };
		},
		data() {
			return {
				extended: false,
				loading: false,
			};
		},
		computed: {
			headers() {
				let headers = [
					{ text: "Name", value: "displayName" },
					{ text: "Email", value: "email" },
					{ text: "", value: "actions" },
				];
				return headers;
			},
		},
		methods: {
			async _createUser() {
				this.loading = true;
				await this.userStore.createUser();
				this.loading = false;
				this.extended = false;
			},
			async _deleteUser(item) {
				this.loading = true;
				// delete on server
				await this.userStore.deleteUser(item.id);
				// delete on client
				let _idx = this.userStore.userList.indexOf(item);
				this.userStore.userList.splice(_idx, 1);
				this.loading = false;
			},
			async _fetchUsers() {
				this.loading = true;
				await this.userStore.fetchUsers();
				this.loading = false;
			},
			showAdd() {
				this.userStore.user.displayName = "";
				this.userStore.user.email = "";
				this.extended = true;
			},
			openUserView(uid) {
				this.$router.push({
					name: "User",
					params: { id: uid },
				});
			},
		},
		mounted() {
			this._fetchUsers();
		},
	};
</script>